/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from '../containers/Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from '../containers/ColumnAux'
import LoadingAux from '../containers/LoadingAux'
import ChooseBox from '../containers/ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from './clientInfo'







// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class WizardForPant extends Component {

    basePrice = 0

    STEP_KEYS = {
        productType: '',
        fitPreference: 'FIT PREFERENCE',
        pleat: 'PLEAT',
        sidePockets: 'SIDE POCKETS',
        backPockets: 'BACK POCKETS',
        waistBandType: 'WAIST BAND TYPE',
        pantClosure: 'PANT CLOSURE',  // NEW
        pantBottom: 'PANT BOTTOM',
        monogram: 'MONOGRAM',
        pantMonogramThreadColor: 'PANT MONOGRAM THREAD COLOR', // NEW (conditional)  - dont show on no monogram
        pantButtons: 'PANT BUTTONS', //NEW
        tone: 'TONE',
        fabric: 'FABRIC'
    }

    TONE_KEYS = {
        AllSeasons: 'All Seasons',

        // AllSeasons1: 'All Seasons 1',
        // AllSeasons2: 'All Seasons 2',
        // AllSeasons3: 'All Seasons 3',
        // AllSeasons4: 'All Seasons 4',        
        FallWinter: 'Fall Winter',
        // SpringSummer: 'Spring Summer',
        // JacquardFabrics: 'Jacquard Fabrics',
        // WaterResistant: 'Water Resistant'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }




    state = {
        uploadImage: null,
        selected_FitPreference: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fitPreference : null,
        selected_Pleat: this.props.editingProduct ? this.props.editingProduct.selectedSelections.pleat : null,
        selected_SidePockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.sidePockets : null,
        selected_BackPockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.backPockets : null,
        selected_WaistBandType: this.props.editingProduct ? this.props.editingProduct.selectedSelections.waistBandType : null,
        selected_PantClosure: this.props.editingProduct ? this.props.editingProduct.selectedSelections.pantClosure : null,


        selected_PantBottom: this.props.editingProduct ? this.props.editingProduct.selectedSelections.pantBottom : null,
        selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,
        selected_MonogramThreadColor: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogramThreadColor : null,


        selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,
        selected_Buttons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.selected_Buttons : null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }

        if (this.props.editingProduct) {
            let existingStyle = { ...this.props.editingProduct }
            ns.editingProduct = existingStyle
            // console.log("EDITING WIZRAD OF JACKET")
            // console.log(existingStyle)



            ns.selected_FitPreference = existingStyle.selectedSelections.fitPreference
            ns.selected_Pleat = existingStyle.selectedSelections.pleat
            ns.selected_SidePockets = existingStyle.selectedSelections.sidePockets
            ns.selected_BackPockets = existingStyle.selectedSelections.backPockets
            ns.selected_WaistBandType = existingStyle.selectedSelections.waistBandType
            ns.selected_PantClosure = existingStyle.selectedSelections.pantClosure

            ns.selected_PantBottom = existingStyle.selectedSelections.pantBottom
            ns.selected_Monogram = existingStyle.selectedSelections.monogram
            ns.selected_MonogramThreadColor = existingStyle.selectedSelections.monogramThreadColor

            ns.selected_Tone = existingStyle.selectedSelections.tone
            ns.selected_Fabric = existingStyle.selectedSelections.fabric
            ns.selected_Buttons = existingStyle.selectedSelections.Buttons


        }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }


    // https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Trouser/FitPreference/Comfort.jpg
    // FIT PREFERENCE
    fitPreference_Opts = [
        { optType: "FIT PREFERENCE", styleSelectionId: "", key: 'SLIM', name: 'SLIM', imageSrc: webImageNamed('StyleSelections/Trouser/FitPreference/Slim.jpg') },
        { optType: "FIT PREFERENCE", styleSelectionId: "", key: 'COMFORT', name: 'COMFORT', imageSrc: webImageNamed('StyleSelections/Trouser/FitPreference/Comfort.jpg') },
        { optType: "FIT PREFERENCE", styleSelectionId: "", key: 'LOOSE', name: 'LOOSE', imageSrc: webImageNamed('StyleSelections/Trouser/FitPreference/Loose.jpg') },
    ]
    fitPreference_Step = () => {
        return {
            name: 'How do you like the Fit?',
            key: this.STEP_KEYS.fitPreference,
            opts: this.fitPreference_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }



    // PLEAT  https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Trouser/Pleats/PlainFront.jpg
    pleat_Opts = [
        { optType: "PLEAT", styleSelectionId: "", key: 'PLAIN FRONT', name: 'PLAIN FRONT', imageSrc: webImageNamed('StyleSelections/Trouser/Pleats/PlainFront.jpg') },
        { optType: "PLEAT", styleSelectionId: "", key: 'SINGLE PLEAT', name: 'SINGLE PLEAT', imageSrc: webImageNamed('StyleSelections/Trouser/Pleats/SinglePleat.jpg') },
    ]
    pleat_Step = () => {
        return {
            name: 'How do you like the front pleat?',
            key: this.STEP_KEYS.pleat,
            opts: this.pleat_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // SIDE POCKETS  https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Trouser/Sidepockets/Standard.jpg
    sidePockets_Opts = [
        { optType: "SIDE POCKETS", styleSelectionId: "", key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('StyleSelections/Trouser/Sidepockets/Standard.jpg') },
        { optType: "SIDE POCKETS", styleSelectionId: "", key: 'DOUBLE BESOM (RECOMMENDED)', name: 'DOUBLE BESOM (RECOMMENDED)', imageSrc: webImageNamed('StyleSelections/Trouser/Sidepockets/DoubleBesom.jpg') },
        { optType: "SIDE POCKETS", styleSelectionId: "", key: 'JEAN STYLE', name: 'JEAN STYLE', imageSrc: webImageNamed('StyleSelections/Trouser/Sidepockets/JeanStyle.jpg') },

    ]
    sidePockets_Step = () => {
        return {
            name: 'How would you like the Side Pockets?',
            key: this.STEP_KEYS.sidePockets,
            opts: this.sidePockets_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // BACK POCKETS https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Trouser/Backpockets/NoPockets.jpg
    backPockets_Opts = [
        { optType: "BACK POCKETS", styleSelectionId: "", key: 'NO POCKETS', name: 'NO POCKETS', imageSrc: webImageNamed('StyleSelections/Trouser/Backpockets/NoPockets.jpg') },
        { optType: "BACK POCKETS", styleSelectionId: "", key: 'BUTTON THROUGH LEFT', name: 'BUTTON THROUGH LEFT', imageSrc: webImageNamed('StyleSelections/Trouser/Backpockets/ButtonThroughLeft.jpg') },
        { optType: "BACK POCKETS", styleSelectionId: "", key: 'BUTTON THROUGH RIGHT', name: 'BUTTON THROUGH RIGHT', imageSrc: webImageNamed('StyleSelections/Trouser/Backpockets/ButtonThroughRight.jpg') },
        { optType: "BACK POCKETS", styleSelectionId: "", key: 'BUTTON THROUGH BOTH POCKETS (STANDARD)', name: 'BUTTON THROUGH BOTH POCKETS (STANDARD)', imageSrc: webImageNamed('StyleSelections/Trouser/Backpockets/ButtonThroughTwoPockets.jpg') },
        { optType: "BACK POCKETS", styleSelectionId: "", key: 'FLAP LEFT', name: 'FLAP LEFT', imageSrc: webImageNamed('StyleSelections/Trouser/Backpockets/FlapLeft.jpg') },
        { optType: "BACK POCKETS", styleSelectionId: "", key: 'FLAP RIGHT', name: 'FLAP RIGHT', imageSrc: webImageNamed('StyleSelections/Trouser/Backpockets/FlapRight.jpg') },
        { optType: "BACK POCKETS", styleSelectionId: "", key: 'FLAP BOTH POCKETS', name: 'FLAP BOTH POCKETS', imageSrc: webImageNamed('StyleSelections/Trouser/Backpockets/FlapBothPockets.jpg') },

    ]
    backPockets_Step = () => {
        return {
            name: 'Back Pockets',
            key: this.STEP_KEYS.backPockets,
            opts: this.backPockets_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // WAIST BAND TYPE  https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Trouser/WaistBand/None.jpg
    waistBandType_Opts = [
        { optType: "WAIST BAND TYPE", styleSelectionId: "", key: 'NONE', name: 'NONE', imageSrc: webImageNamed('StyleSelections/Trouser/WaistBand/None.jpg') },
        { optType: "WAIST BAND TYPE", styleSelectionId: "", key: 'BELT LOOP (STANDARD)', name: 'BELT LOOP (STANDARD)', imageSrc: webImageNamed('StyleSelections/Trouser/WaistBand/BeltLoop.jpg') },
        { optType: "WAIST BAND TYPE", styleSelectionId: "", key: 'SIDE ADJUSTER WITH BUCKLE', name: 'SIDE ADJUSTER WITH BUCKLE', imageSrc: webImageNamed('StyleSelections/Trouser/WaistBand/SideAdjusterWithBuckle.jpg') },

    ]
    waistBandType_Step = () => {
        return {
            name: 'Waist Band Type',
            key: this.STEP_KEYS.waistBandType,
            opts: this.waistBandType_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: true,
            paddingLess: false,
            boldText: false
        }
    }



    pantClosure_Opts = [
        { optType: "Pant Closure", styleSelectionId: "", key: 'Button', name: 'Button', imageSrc: webImageNamed('StyleSelections/Trouser/PantClosure/Button.jpg') },
        { optType: "Pant Closure", styleSelectionId: "", key: 'Extended Closure', name: 'Extended Closure', imageSrc: webImageNamed('StyleSelections/Trouser/PantClosure/ExtendedClosure.jpg') },

    ]
    pantClosure_Step = () => {
        return {
            name: 'Pant Closure',
            key: this.STEP_KEYS.pantClosure,
            opts: this.pantClosure_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: true,
            paddingLess: false,
            boldText: false
        }
    }




    // PANT BOTTOM  https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Trouser/Pantbottom/Slim.jpg
    pantBottom_Opts = [
        { optType: "PANT BOTTOM", styleSelectionId: "", key: 'NORMAL', name: 'NORMAL', imageSrc: webImageNamed('StyleSelections/Trouser/Pantbottom/Normal.jpg') },
        { optType: "PANT BOTTOM", styleSelectionId: "", key: 'SLIM CUFF (1 INCH)', name: 'SLIM CUFF (1 INCH)', imageSrc: webImageNamed('StyleSelections/Trouser/Pantbottom/Slim.jpg') },
        { optType: "PANT BOTTOM", styleSelectionId: "", key: 'MEDIUM CUFF (1.5 INCH)', name: 'MEDIUM CUFF (1.5 INCH)', imageSrc: webImageNamed('StyleSelections/Trouser/Pantbottom/Medium.jpg') },
        { optType: "PANT BOTTOM", styleSelectionId: "", key: 'WIDE CUFF (2 INCH)', name: 'WIDE CUFF (2 INCH)', imageSrc: webImageNamed('StyleSelections/Trouser/Pantbottom/Wide.jpg') },
    ]
    pantBottom_Step = () => {
        return {
            name: 'How about Pant Bottom',
            key: this.STEP_KEYS.pantBottom,
            opts: this.pantBottom_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // MONOGRAM   https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/xavirow/StyleSelections/Trouser/Monogram/No.jpg
    monogram_Opts = [
        { optType: "MONOGRAM", styleSelectionId: "", key: 'NO MONOGRAM', name: 'NO MONOGRAM', imageSrc: null, customInput: "" },
        { optType: "MONOGRAM", styleSelectionId: "", key: 'MONOGRAM INSIDE WAISTBAND', name: 'MONOGRAM INSIDE WAISTBAND', price: 10, imageSrc: webImageNamed('StyleSelections/Trouser/Monogram/MonogramInsideWaistBand.jpg'), customInput: "" },
    ]
    monogram_Step = () => {
        return {
            name: 'If and where you would like to have monogram',
            key: this.STEP_KEYS.monogram,
            opts: this.monogram_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: true,
            paddingLess: false,
            boldText: false,
            needsCustomInput: true
        }
    }

    pantMonogramThreadColor_Opts = [
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '101', name: '101', imageSrc: webImageNamed('Fabrics/MonogramThread/101.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '102', name: '102', imageSrc: webImageNamed('Fabrics/MonogramThread/102.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '103', name: '103', imageSrc: webImageNamed('Fabrics/MonogramThread/103.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '138', name: '138', imageSrc: webImageNamed('Fabrics/MonogramThread/138.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '144', name: '144', imageSrc: webImageNamed('Fabrics/MonogramThread/144.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '145', name: '145', imageSrc: webImageNamed('Fabrics/MonogramThread/145.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '177', name: '177', imageSrc: webImageNamed('Fabrics/MonogramThread/177.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '189', name: '189', imageSrc: webImageNamed('Fabrics/MonogramThread/189.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '262', name: '262', imageSrc: webImageNamed('Fabrics/MonogramThread/262.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '312', name: '312', imageSrc: webImageNamed('Fabrics/MonogramThread/312.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '321', name: '321', imageSrc: webImageNamed('Fabrics/MonogramThread/321.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '352', name: '352', imageSrc: webImageNamed('Fabrics/MonogramThread/352.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '392', name: '392', imageSrc: webImageNamed('Fabrics/MonogramThread/392.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '432', name: '432', imageSrc: webImageNamed('Fabrics/MonogramThread/432.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '455', name: '455', imageSrc: webImageNamed('Fabrics/MonogramThread/455.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '489', name: '489', imageSrc: webImageNamed('Fabrics/MonogramThread/489.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '572', name: '572', imageSrc: webImageNamed('Fabrics/MonogramThread/572.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '598', name: '598', imageSrc: webImageNamed('Fabrics/MonogramThread/598.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '599', name: '599', imageSrc: webImageNamed('Fabrics/MonogramThread/599.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '624', name: '624', imageSrc: webImageNamed('Fabrics/MonogramThread/624.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '633', name: '633', imageSrc: webImageNamed('Fabrics/MonogramThread/633.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '714', name: '714', imageSrc: webImageNamed('Fabrics/MonogramThread/714.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1017', name: '1017', imageSrc: webImageNamed('Fabrics/MonogramThread/1017.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1034', name: '1034', imageSrc: webImageNamed('Fabrics/MonogramThread/1034.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1059', name: '1059', imageSrc: webImageNamed('Fabrics/MonogramThread/1059.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1093', name: '1093', imageSrc: webImageNamed('Fabrics/MonogramThread/1093.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1116', name: '1116', imageSrc: webImageNamed('Fabrics/MonogramThread/1116.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1161', name: '1161', imageSrc: webImageNamed('Fabrics/MonogramThread/1161.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1162', name: '1162', imageSrc: webImageNamed('Fabrics/MonogramThread/1162.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1173', name: '1173', imageSrc: webImageNamed('Fabrics/MonogramThread/1173.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1196', name: '1196', imageSrc: webImageNamed('Fabrics/MonogramThread/1196.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '1203', name: '1203', imageSrc: webImageNamed('Fabrics/MonogramThread/1203.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3077', name: '3077', imageSrc: webImageNamed('Fabrics/MonogramThread/3077.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3099', name: '3099', imageSrc: webImageNamed('Fabrics/MonogramThread/3099.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3172', name: '3172', imageSrc: webImageNamed('Fabrics/MonogramThread/3172.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3334', name: '3334', imageSrc: webImageNamed('Fabrics/MonogramThread/3334.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3601', name: '3601', imageSrc: webImageNamed('Fabrics/MonogramThread/3601.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3618', name: '3618', imageSrc: webImageNamed('Fabrics/MonogramThread/3618.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3655', name: '3655', imageSrc: webImageNamed('Fabrics/MonogramThread/3655.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3687', name: '3687', imageSrc: webImageNamed('Fabrics/MonogramThread/3687.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3694', name: '3694', imageSrc: webImageNamed('Fabrics/MonogramThread/3694.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3701', name: '3701', imageSrc: webImageNamed('Fabrics/MonogramThread/3701.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3712', name: '3712', imageSrc: webImageNamed('Fabrics/MonogramThread/3712.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3720', name: '3720', imageSrc: webImageNamed('Fabrics/MonogramThread/3720.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3742', name: '3742', imageSrc: webImageNamed('Fabrics/MonogramThread/3742.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3747', name: '3747', imageSrc: webImageNamed('Fabrics/MonogramThread/3747.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3750', name: '3750', imageSrc: webImageNamed('Fabrics/MonogramThread/3750.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: '3813', name: '3813', imageSrc: webImageNamed('Fabrics/MonogramThread/3813.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: 'MG2', name: 'MG2', imageSrc: webImageNamed('Fabrics/MonogramThread/MG2.jpg'), price: null, company: '' },
        { optType: this.STEP_KEYS.pantMonogramThreadColor, key: 'MS1', name: 'MS1', imageSrc: webImageNamed('Fabrics/MonogramThread/MS1.jpg'), price: null, company: '' },
    ]
    pantMonogramThreadColor_Step = () => {
        return {
            name: 'Select Monogram Thread Color',
            key: this.STEP_KEYS.pantMonogramThreadColor,
            opts: this.pantMonogramThreadColor_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }




    // SUIT BUTTONS
    pantButtons_Opts = [
        { optType: "Buttons", key: 'KB001', name: 'KB001', imageSrc: webImageNamed('Fabrics/SuitButtons/KB001.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB002', name: 'KB002', imageSrc: webImageNamed('Fabrics/SuitButtons/KB002.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB008', name: 'KB008', imageSrc: webImageNamed('Fabrics/SuitButtons/KB008.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB010', name: 'KB010', imageSrc: webImageNamed('Fabrics/SuitButtons/KB010.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB011', name: 'KB011', imageSrc: webImageNamed('Fabrics/SuitButtons/KB011.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB016', name: 'KB016', imageSrc: webImageNamed('Fabrics/SuitButtons/KB016.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB020', name: 'KB020', imageSrc: webImageNamed('Fabrics/SuitButtons/KB020.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB021', name: 'KB021', imageSrc: webImageNamed('Fabrics/SuitButtons/KB021.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB022', name: 'KB022', imageSrc: webImageNamed('Fabrics/SuitButtons/KB022.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB023', name: 'KB023', imageSrc: webImageNamed('Fabrics/SuitButtons/KB023.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB025', name: 'KB025', imageSrc: webImageNamed('Fabrics/SuitButtons/KB025.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB026', name: 'KB026', imageSrc: webImageNamed('Fabrics/SuitButtons/KB026.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB027', name: 'KB027', imageSrc: webImageNamed('Fabrics/SuitButtons/KB027.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB028', name: 'KB028', imageSrc: webImageNamed('Fabrics/SuitButtons/KB028.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB029', name: 'KB029', imageSrc: webImageNamed('Fabrics/SuitButtons/KB029.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KB030', name: 'KB030', imageSrc: webImageNamed('Fabrics/SuitButtons/KB030.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG024', name: 'KG024', imageSrc: webImageNamed('Fabrics/SuitButtons/KG024.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG027', name: 'KG027', imageSrc: webImageNamed('Fabrics/SuitButtons/KG027.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG032', name: 'KG032', imageSrc: webImageNamed('Fabrics/SuitButtons/KG032.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG053', name: 'KG053', imageSrc: webImageNamed('Fabrics/SuitButtons/KG053.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG054', name: 'KG054', imageSrc: webImageNamed('Fabrics/SuitButtons/KG054.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG055', name: 'KG055', imageSrc: webImageNamed('Fabrics/SuitButtons/KG055.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG056', name: 'KG056', imageSrc: webImageNamed('Fabrics/SuitButtons/KG056.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG057', name: 'KG057', imageSrc: webImageNamed('Fabrics/SuitButtons/KG057.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG062', name: 'KG062', imageSrc: webImageNamed('Fabrics/SuitButtons/KG062.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG064', name: 'KG064', imageSrc: webImageNamed('Fabrics/SuitButtons/KG064.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG068', name: 'KG068', imageSrc: webImageNamed('Fabrics/SuitButtons/KG068.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG069', name: 'KG069', imageSrc: webImageNamed('Fabrics/SuitButtons/KG069.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG157', name: 'KG157', imageSrc: webImageNamed('Fabrics/SuitButtons/KG157.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG168', name: 'KG168', imageSrc: webImageNamed('Fabrics/SuitButtons/KG168.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG178', name: 'KG178', imageSrc: webImageNamed('Fabrics/SuitButtons/KG178.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG190', name: 'KG190', imageSrc: webImageNamed('Fabrics/SuitButtons/KG190.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG191', name: 'KG191', imageSrc: webImageNamed('Fabrics/SuitButtons/KG191.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG192', name: 'KG192', imageSrc: webImageNamed('Fabrics/SuitButtons/KG192.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG195', name: 'KG195', imageSrc: webImageNamed('Fabrics/SuitButtons/KG195.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG196', name: 'KG196', imageSrc: webImageNamed('Fabrics/SuitButtons/KG196.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG197', name: 'KG197', imageSrc: webImageNamed('Fabrics/SuitButtons/KG197.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG198', name: 'KG198', imageSrc: webImageNamed('Fabrics/SuitButtons/KG198.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG199', name: 'KG199', imageSrc: webImageNamed('Fabrics/SuitButtons/KG199.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG202', name: 'KG202', imageSrc: webImageNamed('Fabrics/SuitButtons/KG202.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG203', name: 'KG203', imageSrc: webImageNamed('Fabrics/SuitButtons/KG203.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG204', name: 'KG204', imageSrc: webImageNamed('Fabrics/SuitButtons/KG204.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG206', name: 'KG206', imageSrc: webImageNamed('Fabrics/SuitButtons/KG206.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KG212', name: 'KG212', imageSrc: webImageNamed('Fabrics/SuitButtons/KG212.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ011', name: 'KNJ011', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ011.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ012', name: 'KNJ012', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ012.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ013', name: 'KNJ013', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ013.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ014', name: 'KNJ014', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ014.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ023', name: 'KNJ023', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ023.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ024', name: 'KNJ024', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ024.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ030', name: 'KNJ030', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ030.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ031', name: 'KNJ031', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ031.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ033', name: 'KNJ033', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ033.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ036', name: 'KNJ036', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ036.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KNJ039', name: 'KNJ039', imageSrc: webImageNamed('Fabrics/SuitButtons/KNJ039.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KQ022', name: 'KQ022', imageSrc: webImageNamed('Fabrics/SuitButtons/KQ022.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ133', name: 'KSZ133', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ133.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ134', name: 'KSZ134', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ134.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ137', name: 'KSZ137', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ137.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ138', name: 'KSZ138', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ138.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ139', name: 'KSZ139', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ139.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ140', name: 'KSZ140', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ140.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ150', name: 'KSZ150', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ150.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ151', name: 'KSZ151', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ151.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ152', name: 'KSZ152', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ152.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ153', name: 'KSZ153', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ153.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ154', name: 'KSZ154', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ154.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ155', name: 'KSZ155', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ155.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ161', name: 'KSZ161', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ161.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ162', name: 'KSZ162', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ162.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ165', name: 'KSZ165', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ165.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ175', name: 'KSZ175', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ175.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ176', name: 'KSZ176', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ176.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ177', name: 'KSZ177', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ177.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ191', name: 'KSZ191', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ191.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ192', name: 'KSZ192', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ192.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ193', name: 'KSZ193', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ193.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ195', name: 'KSZ195', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ195.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ196', name: 'KSZ196', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ196.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ197', name: 'KSZ197', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ197.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ198', name: 'KSZ198', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ198.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ199', name: 'KSZ199', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ199.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ200', name: 'KSZ200', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ200.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ201', name: 'KSZ201', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ201.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ205', name: 'KSZ205', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ205.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ206', name: 'KSZ206', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ206.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ207', name: 'KSZ207', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ207.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ220', name: 'KSZ220', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ220.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KSZ221', name: 'KSZ221', imageSrc: webImageNamed('Fabrics/SuitButtons/KSZ221.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW001', name: 'KW001', imageSrc: webImageNamed('Fabrics/SuitButtons/KW001.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW002', name: 'KW002', imageSrc: webImageNamed('Fabrics/SuitButtons/KW002.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW003', name: 'KW003', imageSrc: webImageNamed('Fabrics/SuitButtons/KW003.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW004', name: 'KW004', imageSrc: webImageNamed('Fabrics/SuitButtons/KW004.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW005', name: 'KW005', imageSrc: webImageNamed('Fabrics/SuitButtons/KW005.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW006', name: 'KW006', imageSrc: webImageNamed('Fabrics/SuitButtons/KW006.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW017', name: 'KW017', imageSrc: webImageNamed('Fabrics/SuitButtons/KW017.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW042', name: 'KW042', imageSrc: webImageNamed('Fabrics/SuitButtons/KW042.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW043', name: 'KW043', imageSrc: webImageNamed('Fabrics/SuitButtons/KW043.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW044', name: 'KW044', imageSrc: webImageNamed('Fabrics/SuitButtons/KW044.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW045', name: 'KW045', imageSrc: webImageNamed('Fabrics/SuitButtons/KW045.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW063', name: 'KW063', imageSrc: webImageNamed('Fabrics/SuitButtons/KW063.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW065', name: 'KW065', imageSrc: webImageNamed('Fabrics/SuitButtons/KW065.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW066', name: 'KW066', imageSrc: webImageNamed('Fabrics/SuitButtons/KW066.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW067', name: 'KW067', imageSrc: webImageNamed('Fabrics/SuitButtons/KW067.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW068', name: 'KW068', imageSrc: webImageNamed('Fabrics/SuitButtons/KW068.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW069', name: 'KW069', imageSrc: webImageNamed('Fabrics/SuitButtons/KW069.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW070', name: 'KW070', imageSrc: webImageNamed('Fabrics/SuitButtons/KW070.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'KW071', name: 'KW071', imageSrc: webImageNamed('Fabrics/SuitButtons/KW071.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'QMJQZ03', name: 'QMJQZ03', imageSrc: webImageNamed('Fabrics/SuitButtons/QMJQZ03.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'QZMAB01', name: 'QZMAB01', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB01.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'QZMAB02', name: 'QZMAB02', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB02.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'QZMAB03', name: 'QZMAB03', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB03.jpg'), price: null, company: '' },
        { optType: "Buttons", key: 'QZMAB08', name: 'QZMAB08', imageSrc: webImageNamed('Fabrics/SuitButtons/QZMAB08.jpg'), price: null, company: '' },
    ]
    pantButtons_Step = () => {
        // Change images here with respect to button choices
        return {
            name: 'Choose Buttons',
            key: this.STEP_KEYS.pantButtons,
            opts: this.pantButtons_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    // AllSeasons: 'All Seasons',
    // FallWinter: 'Fall Winter',
    // SpringSummer: 'Spring Summer',
    // JacquardFabrics: 'Jacquard Fabrics',
    // WaterResistant: 'Water Resistant'



    // TONE
    toneOpts = [
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.AllSeasons, name: 'ALL SEASONS', imageSrc: webImageNamed('Fabrics/FabricCategory/AllSeason.jpg') },
        { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.FallWinter, name: 'FALL WINTER', imageSrc: webImageNamed('Fabrics/FabricCategory/Fall%26Winter.jpg') },
        // { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.SpringSummer, name: 'SPRING SUMMER', imageSrc: webImageNamed('Fabrics/FabricCategory/Spring%26Summer.jpg') },
        // { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.JacquardFabrics, name: 'JACQUARD FABRICS', imageSrc: webImageNamed('Fabrics/FabricCategory/JackardFabric.jpg') },
        // { optType: "TONE", styleSelectionId: "", key: this.TONE_KEYS.WaterResistant, name: 'WATER RESISTANT (All Seasons)', imageSrc: webImageNamed('Fabrics/FabricCategory/WaterResistant.jpg') },
    ]
    toneStep = () => {
        // Change images here with respect to button choices
        return {
            name: 'What Category of Fabrics are we looking for?',
            key: this.STEP_KEYS.tone,
            opts: this.toneOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }


    // FABRIC, ["PANT"], []


    fabric_AllSeasons = [
        { optType: "Fabric", key: 'DBK052A', name: 'DBK052A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK052A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBK053A', name: 'DBK053A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK053A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBK504A', name: 'DBK504A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK504A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBK544A', name: 'DBK544A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBK544A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBL508A', name: 'DBL508A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL508A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBL622A', name: 'DBL622A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL622A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBL646A', name: 'DBL646A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL646A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBL647A', name: 'DBL647A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL647A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBL648A', name: 'DBL648A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL648A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBL650A', name: 'DBL650A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBL650A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM187A', name: 'DBM187A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM187A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM560A', name: 'DBM560A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM560A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM561A', name: 'DBM561A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM561A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM562A', name: 'DBM562A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM562A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM582A', name: 'DBM582A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM582A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM587A', name: 'DBM587A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM587A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM588A', name: 'DBM588A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM588A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM590A', name: 'DBM590A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM590A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM599A', name: 'DBM599A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM599A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM601A', name: 'DBM601A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM601A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM619A', name: 'DBM619A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM619A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM706A', name: 'DBM706A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM706A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM707A', name: 'DBM707A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM707A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBM742A', name: 'DBM742A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBM742A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN233A', name: 'DBN233A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN233A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN294A', name: 'DBN294A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN294A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN296A', name: 'DBN296A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN296A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN314A', name: 'DBN314A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN314A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN315A', name: 'DBN315A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN315A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN317A', name: 'DBN317A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN317A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN318A', name: 'DBN318A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN318A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN319A', name: 'DBN319A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN319A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN337A', name: 'DBN337A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN337A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN342A', name: 'DBN342A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN342A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN530A', name: 'DBN530A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN530A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN801A', name: 'DBN801A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN801A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN802A', name: 'DBN802A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN802A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN803A', name: 'DBN803A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN803A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN804A', name: 'DBN804A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN804A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN805A', name: 'DBN805A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN805A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN807A', name: 'DBN807A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN807A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN808A', name: 'DBN808A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN808A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN839A', name: 'DBN839A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN839A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN840A', name: 'DBN840A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN840A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN889A', name: 'DBN889A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN889A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN890A', name: 'DBN890A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN890A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN910A', name: 'DBN910A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN910A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBN975A', name: 'DBN975A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBN975A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP323A', name: 'DBP323A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP323A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP325A', name: 'DBP325A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP325A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP536A', name: 'DBP536A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP536A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP537A', name: 'DBP537A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP537A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP625A', name: 'DBP625A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP625A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP626A', name: 'DBP626A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP626A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP627A', name: 'DBP627A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP627A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP628A', name: 'DBP628A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP628A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP629A', name: 'DBP629A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP629A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP639A', name: 'DBP639A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP639A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP643A', name: 'DBP643A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP643A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP645A', name: 'DBP645A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP645A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP658A', name: 'DBP658A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP658A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP659A', name: 'DBP659A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP659A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP660A', name: 'DBP660A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP660A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP661A', name: 'DBP661A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP661A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP662A', name: 'DBP662A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP662A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP663A', name: 'DBP663A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP663A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP665A', name: 'DBP665A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP665A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP668A', name: 'DBP668A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP668A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP669A', name: 'DBP669A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP669A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP671A', name: 'DBP671A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP671A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP672A', name: 'DBP672A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP672A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP673A', name: 'DBP673A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP673A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP674A', name: 'DBP674A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP674A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP676A', name: 'DBP676A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP676A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP677A', name: 'DBP677A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP677A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP678A', name: 'DBP678A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP678A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP681A', name: 'DBP681A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP681A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP683A', name: 'DBP683A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP683A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP684A', name: 'DBP684A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP684A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP686A', name: 'DBP686A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP686A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP688A', name: 'DBP688A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP688A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP689A', name: 'DBP689A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP689A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP690A', name: 'DBP690A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP690A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP691A', name: 'DBP691A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP691A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP693A', name: 'DBP693A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP693A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP697A', name: 'DBP697A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP697A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP702A', name: 'DBP702A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP702A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP703A', name: 'DBP703A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP703A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP704A', name: 'DBP704A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP704A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP705A', name: 'DBP705A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP705A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP706A', name: 'DBP706A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP706A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP707A', name: 'DBP707A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP707A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP737A', name: 'DBP737A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP737A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP738A', name: 'DBP738A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP738A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP739A', name: 'DBP739A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP739A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP740A', name: 'DBP740A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP740A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP741A', name: 'DBP741A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP741A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP748A', name: 'DBP748A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP748A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP751A', name: 'DBP751A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP751A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP752A', name: 'DBP752A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP752A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP753A', name: 'DBP753A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP753A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP754A', name: 'DBP754A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP754A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP942A', name: 'DBP942A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP942A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBP947A', name: 'DBP947A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBP947A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ567A', name: 'DBQ567A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ567A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ568A', name: 'DBQ568A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ568A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ569A', name: 'DBQ569A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ569A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ662A', name: 'DBQ662A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ662A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ786A', name: 'DBQ786A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ786A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ787A', name: 'DBQ787A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ787A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ788A', name: 'DBQ788A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ788A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ789A', name: 'DBQ789A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ789A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ790A', name: 'DBQ790A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ790A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ791A', name: 'DBQ791A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ791A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ793A', name: 'DBQ793A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ793A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ794A', name: 'DBQ794A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ794A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ795A', name: 'DBQ795A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ795A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ796A', name: 'DBQ796A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ796A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ797A', name: 'DBQ797A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ797A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ801A', name: 'DBQ801A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ801A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ803A', name: 'DBQ803A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ803A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ810A', name: 'DBQ810A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ810A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ811A', name: 'DBQ811A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ811A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ812A', name: 'DBQ812A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ812A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ813A', name: 'DBQ813A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ813A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ815A', name: 'DBQ815A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ815A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ817A', name: 'DBQ817A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ817A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBQ818A', name: 'DBQ818A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBQ818A.jpg'), price: 300, company: '' },
    ]

    fabric_AllSeasons2 = [
        { optType: "Fabric", key: 'DBR109A', name: 'DBR109A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR109A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR110A', name: 'DBR110A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR110A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR111A', name: 'DBR111A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR111A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR112A', name: 'DBR112A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR112A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR113A', name: 'DBR113A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR113A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR115A', name: 'DBR115A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR115A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR116A', name: 'DBR116A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR116A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR117A', name: 'DBR117A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR117A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR118A', name: 'DBR118A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR118A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR119A', name: 'DBR119A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR119A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR120A', name: 'DBR120A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR120A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR128A', name: 'DBR128A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR128A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR136A', name: 'DBR136A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR136A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR137A', name: 'DBR137A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR137A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR140A', name: 'DBR140A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR140A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR179A', name: 'DBR179A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR179A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR180A', name: 'DBR180A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR180A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR181A', name: 'DBR181A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR181A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR182A', name: 'DBR182A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR182A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR183A', name: 'DBR183A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR183A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR186A', name: 'DBR186A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR186A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR191A', name: 'DBR191A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR191A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR192A', name: 'DBR192A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR192A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR204A', name: 'DBR204A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR204A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR205A', name: 'DBR205A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR205A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR207A', name: 'DBR207A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR207A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR210A', name: 'DBR210A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR210A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR219A', name: 'DBR219A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR219A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR220A', name: 'DBR220A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR220A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR229A', name: 'DBR229A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR229A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR230A', name: 'DBR230A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR230A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR231A', name: 'DBR231A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR231A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR232A', name: 'DBR232A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR232A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR233A', name: 'DBR233A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR233A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR234A', name: 'DBR234A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR234A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR235A', name: 'DBR235A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR235A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBR253A', name: 'DBR253A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBR253A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS131A', name: 'DBS131A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS131A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS132A', name: 'DBS132A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS132A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS133A', name: 'DBS133A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS133A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS134A', name: 'DBS134A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS134A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS135A', name: 'DBS135A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS135A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS136A', name: 'DBS136A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS136A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS137A', name: 'DBS137A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS137A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS138A', name: 'DBS138A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS138A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS139A', name: 'DBS139A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS139A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS140A', name: 'DBS140A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS140A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS141A', name: 'DBS141A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS141A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS142A', name: 'DBS142A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS142A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS144A', name: 'DBS144A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS144A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS146A', name: 'DBS146A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS146A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS147A', name: 'DBS147A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS147A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS152A', name: 'DBS152A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS152A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS153A', name: 'DBS153A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS153A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS156A', name: 'DBS156A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS156A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS157A', name: 'DBS157A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS157A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS158A', name: 'DBS158A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS158A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS162A', name: 'DBS162A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS162A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS163A', name: 'DBS163A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS163A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS164A', name: 'DBS164A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS164A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS165A', name: 'DBS165A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS165A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS166A', name: 'DBS166A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS166A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS167A', name: 'DBS167A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS167A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS168A', name: 'DBS168A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS168A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS169A', name: 'DBS169A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS169A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS170A', name: 'DBS170A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS170A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS171A', name: 'DBS171A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS171A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS172A', name: 'DBS172A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS172A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS174A', name: 'DBS174A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS174A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS175A', name: 'DBS175A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS175A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS178A', name: 'DBS178A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS178A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS179A', name: 'DBS179A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS179A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS181A', name: 'DBS181A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS181A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS184A', name: 'DBS184A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS184A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS186A', name: 'DBS186A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS186A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS195A', name: 'DBS195A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS195A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS196A', name: 'DBS196A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS196A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS212A', name: 'DBS212A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS212A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS222A', name: 'DBS222A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS222A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS223A', name: 'DBS223A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS223A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS231A', name: 'DBS231A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS231A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS232A', name: 'DBS232A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS232A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS233A', name: 'DBS233A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS233A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS234A', name: 'DBS234A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS234A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS235A', name: 'DBS235A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS235A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS251A', name: 'DBS251A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS251A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS253A', name: 'DBS253A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBS253A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT005A', name: 'DBT005A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT005A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT006A', name: 'DBT006A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT006A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT550A', name: 'DBT550A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT550A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT551A', name: 'DBT551A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT551A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT552A', name: 'DBT552A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT552A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT553A', name: 'DBT553A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT553A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT554A', name: 'DBT554A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT554A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT555A', name: 'DBT555A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT555A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT556A', name: 'DBT556A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT556A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT557A', name: 'DBT557A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT557A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT558A', name: 'DBT558A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT558A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT559A', name: 'DBT559A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT559A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT560A', name: 'DBT560A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT560A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT561A', name: 'DBT561A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT561A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT562A', name: 'DBT562A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT562A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT563A', name: 'DBT563A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT563A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT564A', name: 'DBT564A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT564A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT565A', name: 'DBT565A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT565A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT566A', name: 'DBT566A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT566A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT567A', name: 'DBT567A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT567A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT568A', name: 'DBT568A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT568A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT569A', name: 'DBT569A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT569A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT570A', name: 'DBT570A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT570A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT571A', name: 'DBT571A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT571A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT572A', name: 'DBT572A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT572A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT576A', name: 'DBT576A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT576A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT578A', name: 'DBT578A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT578A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT579A', name: 'DBT579A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT579A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT580A', name: 'DBT580A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT580A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT581A', name: 'DBT581A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT581A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT586A', name: 'DBT586A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT586A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT587A', name: 'DBT587A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT587A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT589A', name: 'DBT589A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT589A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT590A', name: 'DBT590A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT590A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT591A', name: 'DBT591A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT591A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT592A', name: 'DBT592A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT592A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT593A', name: 'DBT593A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT593A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT594A', name: 'DBT594A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT594A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT603A', name: 'DBT603A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT603A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT604A', name: 'DBT604A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT604A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT605A', name: 'DBT605A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT605A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT606A', name: 'DBT606A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT606A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT607A', name: 'DBT607A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT607A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT609A', name: 'DBT609A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT609A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT610A', name: 'DBT610A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT610A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT611A', name: 'DBT611A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT611A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT612A', name: 'DBT612A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT612A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT613A', name: 'DBT613A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT613A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT614A', name: 'DBT614A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT614A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT616A', name: 'DBT616A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT616A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT617A', name: 'DBT617A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT617A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT618A', name: 'DBT618A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT618A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT621A', name: 'DBT621A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT621A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT1503', name: 'DBT1503', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT1503.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT1506', name: 'DBT1506', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBT1506.jpg'), price: 300, company: '' },
    ]


    fabric_AllSeasons3 = [
    { optType: "Fabric", key: 'DBU018A', name: 'DBU018A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU018A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU019A', name: 'DBU019A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU019A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU020A', name: 'DBU020A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU020A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU021A', name: 'DBU021A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU021A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU022A', name: 'DBU022A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU022A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU023A', name: 'DBU023A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU023A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU025A', name: 'DBU025A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU025A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU026A', name: 'DBU026A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU026A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU027A', name: 'DBU027A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU027A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU028A', name: 'DBU028A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU028A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU029A', name: 'DBU029A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU029A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU030A', name: 'DBU030A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU030A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU031A', name: 'DBU031A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU031A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU032A', name: 'DBU032A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU032A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU033A', name: 'DBU033A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU033A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU034A', name: 'DBU034A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU034A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU035A', name: 'DBU035A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU035A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU036A', name: 'DBU036A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU036A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU037A', name: 'DBU037A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU037A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU038A', name: 'DBU038A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU038A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU039A', name: 'DBU039A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU039A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU040A', name: 'DBU040A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU040A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU041A', name: 'DBU041A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU041A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU042A', name: 'DBU042A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU042A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU043A', name: 'DBU043A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU043A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU054A', name: 'DBU054A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU054A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU059A', name: 'DBU059A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU059A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU060A', name: 'DBU060A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU060A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU061A', name: 'DBU061A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU061A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU062A', name: 'DBU062A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU062A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU063A', name: 'DBU063A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU063A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU064A', name: 'DBU064A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU064A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU065A', name: 'DBU065A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU065A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU066A', name: 'DBU066A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU066A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU067A', name: 'DBU067A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU067A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU068A', name: 'DBU068A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU068A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU069A', name: 'DBU069A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU069A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU070A', name: 'DBU070A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU070A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU071A', name: 'DBU071A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU071A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU072A', name: 'DBU072A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU072A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU073A', name: 'DBU073A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU073A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU074A', name: 'DBU074A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU074A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU075A', name: 'DBU075A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU075A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU076A', name: 'DBU076A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU076A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU077A', name: 'DBU077A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU077A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU078A', name: 'DBU078A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU078A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU079A', name: 'DBU079A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU079A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU080A', name: 'DBU080A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU080A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU081A', name: 'DBU081A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU081A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU082A', name: 'DBU082A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU082A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU083A', name: 'DBU083A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU083A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU084A', name: 'DBU084A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU084A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU085A', name: 'DBU085A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU085A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU086A', name: 'DBU086A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU086A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU087A', name: 'DBU087A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU087A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU088A', name: 'DBU088A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU088A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU089A', name: 'DBU089A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU089A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU098A', name: 'DBU098A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU098A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU099A', name: 'DBU099A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU099A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU100A', name: 'DBU100A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU100A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU101A', name: 'DBU101A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU101A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU102A', name: 'DBU102A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU102A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU103A', name: 'DBU103A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU103A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU104A', name: 'DBU104A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU104A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU105A', name: 'DBU105A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU105A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU106A', name: 'DBU106A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU106A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU107A', name: 'DBU107A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU107A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU108A', name: 'DBU108A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU108A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU109A', name: 'DBU109A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU109A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU110A', name: 'DBU110A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU110A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU111A', name: 'DBU111A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU111A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU112A', name: 'DBU112A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU112A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU113A', name: 'DBU113A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU113A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU114A', name: 'DBU114A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU114A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU115A', name: 'DBU115A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU115A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU116A', name: 'DBU116A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU116A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU117A', name: 'DBU117A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU117A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU118A', name: 'DBU118A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU118A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU119A', name: 'DBU119A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU119A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU120A', name: 'DBU120A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU120A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU121A', name: 'DBU121A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU121A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU122A', name: 'DBU122A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU122A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU123A', name: 'DBU123A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU123A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU124A', name: 'DBU124A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU124A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU125A', name: 'DBU125A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU125A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU126A', name: 'DBU126A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU126A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU127A', name: 'DBU127A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU127A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU128A', name: 'DBU128A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU128A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU129A', name: 'DBU129A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU129A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU130A', name: 'DBU130A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU130A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU131A', name: 'DBU131A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU131A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU132A', name: 'DBU132A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU132A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU133A', name: 'DBU133A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU133A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU134A', name: 'DBU134A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU134A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU135A', name: 'DBU135A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU135A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU136A', name: 'DBU136A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU136A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU137A', name: 'DBU137A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU137A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU138A', name: 'DBU138A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU138A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU139A', name: 'DBU139A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU139A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU150A', name: 'DBU150A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU150A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU151A', name: 'DBU151A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU151A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU152A', name: 'DBU152A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU152A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU153A', name: 'DBU153A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU153A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU155A', name: 'DBU155A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU155A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0001', name: 'DHH0001', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0001.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0002', name: 'DHH0002', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0002.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0003', name: 'DHH0003', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0003.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0004', name: 'DHH0004', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0004.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0005', name: 'DHH0005', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0005.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0006', name: 'DHH0006', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0006.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0007', name: 'DHH0007', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0007.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0008', name: 'DHH0008', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0008.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0009', name: 'DHH0009', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0009.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0010', name: 'DHH0010', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0010.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0011', name: 'DHH0011', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0011.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0012', name: 'DHH0012', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0012.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0013', name: 'DHH0013', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0013.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0014', name: 'DHH0014', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0014.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0015', name: 'DHH0015', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0015.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0016', name: 'DHH0016', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0016.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0017', name: 'DHH0017', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0017.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0018', name: 'DHH0018', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0018.jpg'), price: 300, company: '' },
    ]

    fabric_AllSeasons3 = [
    { optType: "Fabric", key: 'DBU018A', name: 'DBU018A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU018A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU019A', name: 'DBU019A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU019A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU020A', name: 'DBU020A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU020A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU021A', name: 'DBU021A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU021A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU022A', name: 'DBU022A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU022A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU023A', name: 'DBU023A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU023A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU025A', name: 'DBU025A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU025A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU026A', name: 'DBU026A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU026A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU027A', name: 'DBU027A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU027A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU028A', name: 'DBU028A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU028A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU029A', name: 'DBU029A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU029A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU030A', name: 'DBU030A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU030A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU031A', name: 'DBU031A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU031A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU032A', name: 'DBU032A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU032A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU033A', name: 'DBU033A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU033A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU034A', name: 'DBU034A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU034A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU035A', name: 'DBU035A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU035A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU036A', name: 'DBU036A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU036A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU037A', name: 'DBU037A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU037A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU038A', name: 'DBU038A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU038A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU039A', name: 'DBU039A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU039A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU040A', name: 'DBU040A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU040A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU041A', name: 'DBU041A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU041A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU042A', name: 'DBU042A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU042A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU043A', name: 'DBU043A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU043A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU054A', name: 'DBU054A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU054A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU059A', name: 'DBU059A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU059A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU060A', name: 'DBU060A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU060A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU061A', name: 'DBU061A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU061A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU062A', name: 'DBU062A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU062A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU063A', name: 'DBU063A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU063A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU064A', name: 'DBU064A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU064A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU065A', name: 'DBU065A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU065A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU066A', name: 'DBU066A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU066A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU067A', name: 'DBU067A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU067A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU068A', name: 'DBU068A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU068A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU069A', name: 'DBU069A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU069A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU070A', name: 'DBU070A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU070A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU071A', name: 'DBU071A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU071A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU072A', name: 'DBU072A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU072A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU073A', name: 'DBU073A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU073A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU074A', name: 'DBU074A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU074A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU075A', name: 'DBU075A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU075A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU076A', name: 'DBU076A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU076A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU077A', name: 'DBU077A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU077A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU078A', name: 'DBU078A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU078A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU079A', name: 'DBU079A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU079A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU080A', name: 'DBU080A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU080A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU081A', name: 'DBU081A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU081A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU082A', name: 'DBU082A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU082A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU083A', name: 'DBU083A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU083A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU084A', name: 'DBU084A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU084A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU085A', name: 'DBU085A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU085A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU086A', name: 'DBU086A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU086A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU087A', name: 'DBU087A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU087A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU088A', name: 'DBU088A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU088A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU089A', name: 'DBU089A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU089A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU098A', name: 'DBU098A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU098A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU099A', name: 'DBU099A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU099A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU100A', name: 'DBU100A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU100A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU101A', name: 'DBU101A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU101A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU102A', name: 'DBU102A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU102A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU103A', name: 'DBU103A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU103A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU104A', name: 'DBU104A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU104A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU105A', name: 'DBU105A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU105A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU106A', name: 'DBU106A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU106A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU107A', name: 'DBU107A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU107A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU108A', name: 'DBU108A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU108A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU109A', name: 'DBU109A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU109A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU110A', name: 'DBU110A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU110A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU111A', name: 'DBU111A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU111A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU112A', name: 'DBU112A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU112A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU113A', name: 'DBU113A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU113A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU114A', name: 'DBU114A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU114A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU115A', name: 'DBU115A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU115A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU116A', name: 'DBU116A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU116A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU117A', name: 'DBU117A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU117A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU118A', name: 'DBU118A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU118A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU119A', name: 'DBU119A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU119A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU120A', name: 'DBU120A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU120A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU121A', name: 'DBU121A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU121A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU122A', name: 'DBU122A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU122A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU123A', name: 'DBU123A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU123A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU124A', name: 'DBU124A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU124A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU125A', name: 'DBU125A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU125A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU126A', name: 'DBU126A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU126A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU127A', name: 'DBU127A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU127A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU128A', name: 'DBU128A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU128A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU129A', name: 'DBU129A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU129A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU130A', name: 'DBU130A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU130A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU131A', name: 'DBU131A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU131A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU132A', name: 'DBU132A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU132A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU133A', name: 'DBU133A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU133A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU134A', name: 'DBU134A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU134A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU135A', name: 'DBU135A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU135A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU136A', name: 'DBU136A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU136A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU137A', name: 'DBU137A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU137A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU138A', name: 'DBU138A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU138A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU139A', name: 'DBU139A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU139A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU150A', name: 'DBU150A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU150A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU151A', name: 'DBU151A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU151A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU152A', name: 'DBU152A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU152A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU153A', name: 'DBU153A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU153A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU155A', name: 'DBU155A', imageSrc: webImageNamed('Fabrics/SuitFabrics/DBU155A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0001', name: 'DHH0001', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0001.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0002', name: 'DHH0002', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0002.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0003', name: 'DHH0003', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0003.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0004', name: 'DHH0004', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0004.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0005', name: 'DHH0005', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0005.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0006', name: 'DHH0006', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0006.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0007', name: 'DHH0007', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0007.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0008', name: 'DHH0008', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0008.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0009', name: 'DHH0009', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0009.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0010', name: 'DHH0010', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0010.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0011', name: 'DHH0011', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0011.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0012', name: 'DHH0012', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0012.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0013', name: 'DHH0013', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0013.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0014', name: 'DHH0014', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0014.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0015', name: 'DHH0015', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0015.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0016', name: 'DHH0016', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0016.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0017', name: 'DHH0017', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0017.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DHH0018', name: 'DHH0018', imageSrc: webImageNamed('Fabrics/SuitFabrics/DHH0018.jpg'), price: 300, company: '' },
    ]

    fabric_AllSeasons4 = [
        { optType: "Fabric", key: 'DBN342A', name: 'DBN342A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN342A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBN530A', name: 'DBN530A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN530A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBN890A', name: 'DBN890A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN890A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBN910A', name: 'DBN910A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBN910A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBR229A', name: 'DBR229A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR229A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBR230A', name: 'DBR230A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR230A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBR231A', name: 'DBR231A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR231A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBR232A', name: 'DBR232A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR232A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBR233A', name: 'DBR233A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR233A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBR234A', name: 'DBR234A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR234A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBR235A', name: 'DBR235A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBR235A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBS178A', name: 'DBS178A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS178A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBS179A', name: 'DBS179A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS179A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBS231A', name: 'DBS231A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS231A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBS232A', name: 'DBS232A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS232A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBS233A', name: 'DBS233A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBS233A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT557A', name: 'DBT557A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT557A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT558A', name: 'DBT558A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT558A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT570A', name: 'DBT570A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT570A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT571A', name: 'DBT571A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT571A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT586A', name: 'DBT586A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT586A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT587A', name: 'DBT587A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT587A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT589A', name: 'DBT589A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT589A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT590A', name: 'DBT590A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT590A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT1503', name: 'DBT1503', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT1503.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBT1506', name: 'DBT1506', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBT1506.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU037A', name: 'DBU037A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU037A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU038A', name: 'DBU038A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU038A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU039A', name: 'DBU039A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU039A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU040A', name: 'DBU040A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU040A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU041A', name: 'DBU041A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU041A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU042A', name: 'DBU042A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU042A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU043A', name: 'DBU043A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU043A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU054A', name: 'DBU054A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU054A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU055A', name: 'DBU055A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU055A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU058A', name: 'DBU058A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU058A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU059A', name: 'DBU059A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU059A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU072A', name: 'DBU072A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU072A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU098A', name: 'DBU098A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU098A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU099A', name: 'DBU099A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU099A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU100A', name: 'DBU100A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU100A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU101A', name: 'DBU101A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU101A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU102A', name: 'DBU102A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU102A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU103A', name: 'DBU103A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU103A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU104A', name: 'DBU104A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU104A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU105A', name: 'DBU105A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU105A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU106A', name: 'DBU106A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU106A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU107A', name: 'DBU107A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU107A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBU108A', name: 'DBU108A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBU108A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV013A', name: 'DBV013A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV013A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV015A', name: 'DBV015A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV015A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV016A', name: 'DBV016A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV016A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV017A', name: 'DBV017A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV017A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV018A', name: 'DBV018A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV018A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV020A', name: 'DBV020A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV020A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV021A', name: 'DBV021A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV021A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV031A', name: 'DBV031A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV031A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV032A', name: 'DBV032A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV032A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV033A', name: 'DBV033A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV033A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV034A', name: 'DBV034A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV034A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV035A', name: 'DBV035A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV035A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV036A', name: 'DBV036A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV036A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV037A', name: 'DBV037A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV037A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV074A', name: 'DBV074A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV074A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV075A', name: 'DBV075A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV075A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV076A', name: 'DBV076A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV076A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV077A', name: 'DBV077A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV077A.jpg'), price: 300, company: '' },
        { optType: "Fabric", key: 'DBV0219A', name: 'DBV0219A', imageSrc: webImageNamed('Fabrics/SuitfabricNo.5/DBV0219A.jpg'), price: 300, company: '' },
    ]
    
    fabric_FallWinter = [
        { optType: "Fabric", key: 'DBQ662A', name: 'DBQ662A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBQ662A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS234A', name: 'DBS234A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBS234A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBS235A', name: 'DBS235A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBS235A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT559A', name: 'DBT559A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT559A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT560A', name: 'DBT560A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT560A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT561A', name: 'DBT561A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT561A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT591A', name: 'DBT591A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT591A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT592A', name: 'DBT592A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT592A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT593A', name: 'DBT593A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT593A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT594A', name: 'DBT594A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT594A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT605A', name: 'DBT605A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT605A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT606A', name: 'DBT606A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT606A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT607A', name: 'DBT607A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT607A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT610A', name: 'DBT610A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT610A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT611A', name: 'DBT611A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT611A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT612A', name: 'DBT612A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT612A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT616A', name: 'DBT616A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT616A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBT618A', name: 'DBT618A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBT618A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU008A', name: 'DBU008A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU008A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU009A', name: 'DBU009A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU009A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU010A', name: 'DBU010A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU010A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU011A', name: 'DBU011A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU011A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU012A', name: 'DBU012A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU012A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU013A', name: 'DBU013A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU013A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU014A', name: 'DBU014A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU014A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU015A', name: 'DBU015A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU015A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU016A', name: 'DBU016A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU016A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU017A', name: 'DBU017A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU017A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU090A', name: 'DBU090A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU090A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU091A', name: 'DBU091A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU091A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU092A', name: 'DBU092A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU092A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBU093A', name: 'DBU093A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBU093A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV008A', name: 'DBV008A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV008A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV009A', name: 'DBV009A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV009A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV011A', name: 'DBV011A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV011A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV012A', name: 'DBV012A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV012A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV038A', name: 'DBV038A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV038A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV039A', name: 'DBV039A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV039A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV050A', name: 'DBV050A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV050A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV051A', name: 'DBV051A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV051A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV052A', name: 'DBV052A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV052A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV053A', name: 'DBV053A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV053A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV054A', name: 'DBV054A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV054A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV055A', name: 'DBV055A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV055A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV056A', name: 'DBV056A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV056A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV057A', name: 'DBV057A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV057A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV058A', name: 'DBV058A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV058A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV080A', name: 'DBV080A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV080A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV081A', name: 'DBV081A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV081A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV082A', name: 'DBV082A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV082A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV083A', name: 'DBV083A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV083A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV084A', name: 'DBV084A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV084A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV085A', name: 'DBV085A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV085A.jpg'), price: 300, company: '' },
    { optType: "Fabric", key: 'DBV086A', name: 'DBV086A', imageSrc: webImageNamed('Fabrics/FallWinterFabrics/DBV086A.jpg'), price: 300, company: '' },
    ]
    fabric_SpringSummer = [
        { optType: "Fabric", key: '2299-1', name: '2299-1', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-1.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-2', name: '2299-2', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-2.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-3', name: '2299-3', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-3.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-4', name: '2299-4', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-4.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-5', name: '2299-5', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-5.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-6', name: '2299-6', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-6.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-7', name: '2299-7', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-7.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-8', name: '2299-8', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-8.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-9', name: '2299-9', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-9.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-10', name: '2299-10', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-10.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-11', name: '2299-11', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-11.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-13', name: '2299-13', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-13.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-101', name: '2299-101', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-101.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-102', name: '2299-102', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-102.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-103', name: '2299-103', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-103.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-104', name: '2299-104', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-104.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-107', name: '2299-107', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-107.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-108', name: '2299-108', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-108.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2299-109', name: '2299-109', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2299-109.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-1', name: '2899-1', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-1.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-2', name: '2899-2', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-2.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-3', name: '2899-3', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-3.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-4', name: '2899-4', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-4.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-5', name: '2899-5', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-5.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-8', name: '2899-8', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-8.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-9', name: '2899-9', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-9.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-10', name: '2899-10', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-10.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-11', name: '2899-11', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-11.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-12', name: '2899-12', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-12.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-13', name: '2899-13', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-13.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-101', name: '2899-101', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-101.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-102', name: '2899-102', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-102.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-103', name: '2899-103', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-103.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-105', name: '2899-105', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-105.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-106', name: '2899-106', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-106.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-107', name: '2899-107', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-107.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '2899-108', name: '2899-108', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/SpringAndSummer900/2899-108.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'Y01', name: 'Y01', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y01.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y10', name: 'Y10', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y10.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y12', name: 'Y12', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y12.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y13', name: 'Y13', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y13.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y14', name: 'Y14', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y14.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y23', name: 'Y23', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y23.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y26', name: 'Y26', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y26.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y27', name: 'Y27', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y27.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y33', name: 'Y33', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y33.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y40', name: 'Y40', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y40.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y41', name: 'Y41', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y41.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y46', name: 'Y46', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y46.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y48', name: 'Y48', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y48.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y54', name: 'Y54', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y54.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y62', name: 'Y62', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y62.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y65', name: 'Y65', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y65.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y68', name: 'Y68', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y68.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y70', name: 'Y70', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y70.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y71', name: 'Y71', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y71.jpg'), price: 300, company: 'Gresham Bloke Linen' },
        { optType: "Fabric", key: 'Y72', name: 'Y72', imageSrc: webImageNamed('Fabrics/SuitFabric/SpringSummer/GreshamBlokeLinen1000/Y72.jpg'), price: 300, company: 'Gresham Bloke Linen' },
    ]
    fabric_JacquardFabrics = [
        { optType: "Fabric", key: 'JF1001', name: 'JF1001', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1001.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1002', name: 'JF1002', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1002.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1003', name: 'JF1003', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1003.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1004', name: 'JF1004', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1004.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1005', name: 'JF1005', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1005.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1006', name: 'JF1006', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1006.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1007', name: 'JF1007', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1007.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1008', name: 'JF1008', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1008.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1009', name: 'JF1009', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1009.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1010', name: 'JF1010', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1010.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1011', name: 'JF1011', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1011.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1012', name: 'JF1012', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1012.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1013', name: 'JF1013', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1013.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1014', name: 'JF1014', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1014.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1015', name: 'JF1015', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1015.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1016', name: 'JF1016', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1016.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1017', name: 'JF1017', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1017.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1018', name: 'JF1018', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1018.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1019', name: 'JF1019', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1019.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1021', name: 'JF1021', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1021.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1022', name: 'JF1022', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1022.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1023', name: 'JF1023', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1023.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1024', name: 'JF1024', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1024.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1025', name: 'JF1025', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1025.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1026', name: 'JF1026', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1026.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1027', name: 'JF1027', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1027.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1028', name: 'JF1028', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1028.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1029', name: 'JF1029', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1029.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1030', name: 'JF1030', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1030.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1031', name: 'JF1031', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1031.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1032', name: 'JF1032', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/JF1032.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1033', name: 'JF1033', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1033.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1034', name: 'JF1034', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1034.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1035', name: 'JF1035', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1035.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1036', name: 'JF1036', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1036.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1037', name: 'JF1037', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1037.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1038', name: 'JF1038', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1038.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1039', name: 'JF1039', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1039.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1040', name: 'JF1040', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1040.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: 'JF1041', name: 'JF1041', imageSrc: webImageNamed('Fabrics/SuitFabric/JacquardFabrics900/VF1041.jpg'), price: 250, company: '' },
    ]
    fabric_WaterResistant = [
        { optType: "Fabric", key: '280101', name: '280101', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280101.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280102', name: '280102', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280102.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280103', name: '280103', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280103.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280104', name: '280104', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280104.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280105', name: '280105', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280105.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280106', name: '280106', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280106.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280107', name: '280107', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280107.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280108', name: '280108', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280108.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280109', name: '280109', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280109.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280110', name: '280110', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280110.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280111', name: '280111', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280111.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280112', name: '280112', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280112.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280131', name: '280131', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280131.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280132', name: '280132', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280132.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280135', name: '280135', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280135.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280136', name: '280136', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280136.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280137', name: '280137', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280137.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280138', name: '280138', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280138.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280139', name: '280139', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280139.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280140', name: '280140', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280140.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280141', name: '280141', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280141.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280142', name: '280142', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280142.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280143', name: '280143', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280143.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280146', name: '280146', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280146.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280147', name: '280147', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280147.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280148', name: '280148', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280148.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280150', name: '280150', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280150.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280154', name: '280154', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280154.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280155', name: '280155', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280155.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280156', name: '280156', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280156.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280159', name: '280159', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280159.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280160', name: '280160', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280160.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280161', name: '280161', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280161.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280165', name: '280165', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280165.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280166', name: '280166', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280166.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280170', name: '280170', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280170.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280180', name: '280180', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280180.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280181', name: '280181', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280181.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280182', name: '280182', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280182.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280183', name: '280183', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280183.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280184', name: '280184', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280184.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280212', name: '280212', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280212.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280213', name: '280213', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280213.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280214', name: '280214', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280214.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280215', name: '280215', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280215.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280216', name: '280216', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280216.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280218', name: '280218', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280218.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280219', name: '280219', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280219.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280220', name: '280220', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280220.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280321', name: '280321', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280321.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280322', name: '280322', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280322.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280323', name: '280323', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280323.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280324', name: '280324', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280324.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280325', name: '280325', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280325.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280326', name: '280326', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280326.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280327', name: '280327', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280327.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280328', name: '280328', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280328.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280329', name: '280329', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280329.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280330', name: '280330', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280330.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280431', name: '280431', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280431.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280432', name: '280432', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280432.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280433', name: '280433', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280433.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280434', name: '280434', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280434.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280435', name: '280435', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280435.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280436', name: '280436', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280436.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280437', name: '280437', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280437.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280438', name: '280438', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280438.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280541', name: '280541', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280541.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280542', name: '280542', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280542.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280543', name: '280543', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280543.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280544', name: '280544', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280544.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280545', name: '280545', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280545.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280546', name: '280546', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280546.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280547', name: '280547', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280547.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280548', name: '280548', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280548.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280549', name: '280549', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280549.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280550', name: '280541', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280541.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280651', name: '280651', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280651.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280653', name: '280653', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280653.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280654', name: '280654', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280654.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280655', name: '280655', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280655.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280656', name: '280656', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280656.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280657', name: '280657', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280657.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280658', name: '280658', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280658.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280659', name: '280659', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280659.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280762', name: '280762', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280762.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280764', name: '280764', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280764.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280765', name: '280765', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280765.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280766', name: '280766', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280766.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280767', name: '280767', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280767.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280768', name: '280768', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280768.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280769', name: '280769', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280769.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280871', name: '280871', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280871.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280872', name: '280872', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280872.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280873', name: '280873', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280873.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280874', name: '280874', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280874.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280875', name: '280875', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280875.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280876', name: '280876', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280876.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280877', name: '280877', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280877.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280878', name: '280878', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280878.jpg'), price: 250, company: '' },
        { optType: "Fabric", key: '280880', name: '280880', imageSrc: webImageNamed('Fabrics/SuitFabric/WateResistant900/280880.jpg'), price: 250, company: '' },
    ]




    fabricImageForSelectedKey = (tone, key) => {


        let opts = null
        switch (tone) {
            case this.TONE_KEYS.fabric_AllSeasons: opts = this.fabric_AllSeasons; break;
            // case this.TONE_KEYS.fabric_AllSeasons1: opts = this.fabric_AllSeasons1; break;
            // case this.TONE_KEYS.fabric_AllSeasons2: opts = this.fabric_AllSeasons2; break;
            // case this.TONE_KEYS.fabric_AllSeasons3: opts = this.fabric_AllSeasons3; break;
            // case this.TONE_KEYS.fabric_AllSeasons4: opts = this.fabric_AllSeasons4; break;

            case this.TONE_KEYS.FallWinter: opts = this.fabric_FallWinter; break;
            // case this.TONE_KEYS.SpringSummer: opts = this.fabric_SpringSummer; break;
            // case this.TONE_KEYS.JacquardFabrics: opts = this.fabric_JacquardFabrics; break;
            // case this.TONE_KEYS.WaterResistant: opts = this.fabric_WaterResistant; break;
            default: opts = this.fabric_AllSeasons; break;
        }
        let thisObj = opts.filter((obj) => {
            return obj.key === key
        })
        return webImageNamed(thisObj[0].imageSrc)
    }


    optsForTone = (tone) => {
        switch (tone) {
            case this.TONE_KEYS.AllSeasons: return this.fabric_AllSeasons; break;

            // case this.TONE_KEYS.AllSeasons1: return this.fabric_AllSeasons1; break;
            // case this.TONE_KEYS.AllSeasons2: return this.fabric_AllSeasons2; break;
            // case this.TONE_KEYS.AllSeasons3: return this.fabric_AllSeasons3; break;
            // case this.TONE_KEYS.AllSeasons4: return this.fabric_AllSeasons4; break;

            case this.TONE_KEYS.FallWinter: return this.fabric_FallWinter; break;
            // case this.TONE_KEYS.SpringSummer: return this.fabric_SpringSummer; break;
            // case this.TONE_KEYS.JacquardFabrics: return this.fabric_JacquardFabrics; break;
            // case this.TONE_KEYS.WaterResistant: return this.fabric_WaterResistant; break;
            default: return this.fabric_AllSeasons; break;
        }
    }

    fabricStep = () => {
        // Change images here with respect to button choices

        // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

        let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.allseason

        let opts = this.optsForTone(thisTone)


        console.log('OPTS NOW =', opts)
        console.log(opts)


        return {
            name: 'Pick a Fabric',
            key: this.STEP_KEYS.fabric,
            opts: opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }



    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }





    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            // CHECK 3
            // const frontImageObj = this.fro.filter((obj) => {
            //     return obj.key === this.state.selected_Pleat
            // })
            let mainImage = imageNamed('singalButton')

            if (this.state.selected_FitPreference.imageSrc) {
                mainImage = this.state.selected_FitPreference.imageSrc
            }

            let fabricImage = null

            if (this.state.selected_Fabric.imageSrc) {
                fabricImage = this.state.selected_Fabric.imageSrc
            }

            let styleName = this.state.productType + "-" + makeid(3)

            if (this.state.editingProduct) {
                uniqId = this.state.editingProduct.key
                styleName = this.state.editingProduct.styleName
            }


            let allSelelected = [this.state.selected_FitPreference, this.state.selected_Pleat, this.state.selected_SidePockets, this.state.selected_BackPockets, this.state.selected_WaistBandType,
            this.state.selected_PantBottom, this.state.selected_Monogram, this.state.selected_Tone, this.state.selected_Fabric]


            let finalPrice = this.basePrice
            let addedPriceObjs = []
            let addOnPrice = 0

            allSelelected.forEach(asel => {
                if (asel != null) {
                    if (asel.price) {
                        finalPrice += asel.price
                        addOnPrice += asel.price
                        addedPriceObjs.push({ type: asel.optType, name: asel.name, price: asel.price })
                    }
                }

            })



            // allSelelected.forEach( selectedObj  => {
            //     if (selelectedObj.price) {
            //         finalPrice += selectedObj.price
            //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
            //     }
            // })

            console.log("this.state.selected_Pleat = ")
            console.log(this.state.selected_Pleat)



            let style = {
                fitPreference: this.state.selected_FitPreference,
                pleat: this.state.selected_Pleat,
                sidePockets: this.state.selected_SidePockets,
                backPockets: this.state.selected_BackPockets,
                waistBandType: this.state.selected_WaistBandType,
                pantClosure: this.state.selected_PantClosure,
                pantBottom: this.state.selected_PantBottom,
                monogram: this.state.selected_Monogram,
                pantMonogramThreadColor: this.state.selected_PantMonogramThreadColor,
                tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric,
                pantButtons: this.state.selected_PantButtons,

            }

            console.log("STYLE = ")
            console.log(style)

            let styleArr = Object.keys(style).map(function (key) {

                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })


            console.log("WITHOUT NULL = ")
            console.log(withoutNull)


            let description = ' '
            withoutNull.forEach((ms, index) => {
                let type = ms[0]

                if (typeof ms[1] !== 'object') {

                    console.log("MS 1 is NOT OBJECT ");
                    console.log(ms);

                }
                let val = ms[1].name



                description += ' ' + type + ':' + val
                if (ms[1].customInput) {
                    description += `<${ms[1].customInput}> `
                }
                if (index == (withoutNull.length - 1)) {
                    description += ""
                } else {
                    description += ","
                }


            })

            // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol, this.basePrice ,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)

            // prodObj.key = 'id' + (new Date()).getTime();

            // // let prodObj = {
            // //     key: uniqId,
            // //     styleName:  styleName,
            // //     frontImage: mainImage,
            // //     fabricImage: fabricImage,
            // //     description: description,
            // //     type: this.state.productType,
            // //     basePrice: this.basePrice,
            // //     price: finalPrice,
            // //     addedPriceObjs: addedPriceObjs,
            // //     selectedSelections: style
            // // }

            let prod = Product.init()
            prod.type = this.state.productType
            prod.conciergeSelections = [style]




            let fabricAndToneMaterials = []

            let concToTwSelections = Object.values(style).map((obj) => {
                if (obj === null) {
                    return null
                }

                if (obj.optType.toLowerCase() === 'fabric') {
                    fabricAndToneMaterials.push("Fabric - " + obj.name + (obj.customInput ? `<${obj.customInput}>` : ""))
                    return null
                }

                if (obj.optType.toLowerCase() === 'tone') {
                    return null
                    // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                }

                return {
                    "key": obj.optType,
                    "value": obj.name + (obj.customInput ? `<${obj.customInput}>` : ""),
                    "styleSelectionId": obj.styleSelectionId ?? "",
                    "imageSrc": obj.imageSrc ?? null,
                    "isConciergeSelection": true
                }
            })

            concToTwSelections = concToTwSelections.filter((obj) => {
                return (obj !== null)
            })

            prod.materialToSource = fabricAndToneMaterials


            prod.styleSelections = concToTwSelections
            prod.basePrice = this.basePrice
            prod.addOnPrice = addOnPrice
            prod.name = styleName
            prod.taxOnPrice = 0
            prod.shippingPrice = 0
            prod.taxDescription = ""
            prod.shippingDescription = ""
            prod.price = finalPrice
            prod.addedPriceObjs = addedPriceObjs
            prod.styleName = styleName
            prod.imageUrl = mainImage
            prod.fabricImage = fabricImage
            prod.imagesUrls = [mainImage, fabricImage]
            prod.description = description
            prod.isBespoke = true
            prod.isViaConcierge = true

            console.log('Sending Style Object -', prod)

            this.props.isDone('created', prod)

        }
    }

    stepItemSelected = (stepKey, itemKey, customInput) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            console.log("GOT Custom INPUT " + customInput)

            // selected_Pleat : pleat
            // selected_SidePockets : sidePockets
            // selected_BackPockets : backPockets
            // selected_PantBottom : pantBottom
            // selected_Lining : lining
            // selected_Monogram : monogram
            // selected_Tone : tone
            // selected_Fabric : fabric

            // ns[stepKey] = itemKey

            // console.log("ITEM KEY =" + itemKey)
            // let firstOPT = this.pleat_Opts.filter( opt => { return (opt.key === itemKey)})


            // console.log("FIRST OPT")
            // console.log(firstOPT)



            switch (stepKey) {
                case this.STEP_KEYS.fitPreference: ns.selected_FitPreference = this.fitPreference_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.pleat: ns.selected_Pleat = this.pleat_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.sidePockets: ns.selected_SidePockets = this.sidePockets_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.backPockets: ns.selected_BackPockets = this.backPockets_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.waistBandType: ns.selected_WaistBandType = this.waistBandType_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.pantClosure: ns.selected_PantClosure = this.pantClosure_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.pantBottom: ns.selected_PantBottom = this.pantBottom_Opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter(opt => { return opt.key === itemKey })[0]; if (customInput && customInput !== "") { ns.selected_Monogram["customInput"] = customInput }; break;
                case this.STEP_KEYS.pantMonogramThreadColor: ns.selected_PantMonogramThreadColor = this.pantMonogramThreadColor_Opts.filter(opt => { return opt.key === itemKey })[0]; break;

                case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter(opt => { return opt.key === itemKey })[0]; break;
                case this.STEP_KEYS.pantButtons: ns.selected_PantButtons = this.pantButtons_Step().opts.filter(opt => { return opt.key === itemKey })[0]; break;

                default: break;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }




    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }






    jacketWizard = () => {


        // selected_Pleat : pleat
        // selected_SidePockets : sidePockets
        // selected_BackPockets : backPockets
        // selected_PantBottom : pantBottom
        // selected_Lining : lining
        // selected_Monogram : monogram
        // selected_Tone : tone
        // selected_Fabric : fabric


        // let productStep = this.productStep()
        let fitPreference_Step = this.fitPreference_Step()
        let pleat_Step = this.pleat_Step()
        let sidePockets_Step = this.sidePockets_Step()
        let backPockets_Step = this.backPockets_Step()
        let waistBandType_Step = this.waistBandType_Step()
        let pantClosure_Step = this.pantClosure_Step()

        let pantBottom_Step = this.pantBottom_Step()
        // let lining_Step = this.lining_Step()
        let monogram_Step = this.monogram_Step()
        let pantMonogramThreadColor_Step = this.pantMonogramThreadColor_Step()

        let toneStep = this.toneStep()
        let fabricStep = this.fabricStep()
        let pantButtons_Step = this.pantButtons_Step()


        // monogramThreadColor conditioned on monogram
        let is_pantMonogramThreadColor_Needed = false
        if (this.state.selected_Monogram) {
            if (this.state.selected_Monogram !== this.monogram_Opts[0])  {
                is_pantMonogramThreadColor_Needed = true
            }
        } 



        let title = "Let's Create a Pant Style"
        let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
        let points = [
            'To make it easy we pick the cuts and tones before finding the fabric.',
            'We will measure you right after we have created all the needed styles.'
        ]

        return (<div >
            {/* <div ref={this.mainStepperDiv}></div> */}
            {(this.state.isComplete || this.state.isCancelled) ?
                <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                :
                <StepWizard className=' fadeIn'>
                    {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
                    {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"12whfu3"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step>}
                    <Step name={fitPreference_Step.name} skipable={fitPreference_Step.skipable ?? false} stepKey={fitPreference_Step.key} exitingSelectedKey={this.state.fitPreference_Step ? this.state.fitPreference_Step.key : null} opts={fitPreference_Step.opts} isFirst={true} isPhotoBox={fitPreference_Step.isPhotoBox} photoRatio={fitPreference_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fitPreference_Step.boldText ?? false} boldText={fitPreference_Step.boldText ?? false} ></Step>
                    <Step name={pleat_Step.name} skipable={pleat_Step.skipable ?? false} stepKey={pleat_Step.key} exitingSelectedKey={this.state.selected_Pleat ? this.state.selected_Pleat.key : null} opts={pleat_Step.opts} isFirst={false} isPhotoBox={pleat_Step.isPhotoBox} photoRatio={pleat_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pleat_Step.boldText ?? false} boldText={pleat_Step.boldText ?? false} ></Step>
                    <Step name={sidePockets_Step.name} skipable={sidePockets_Step.skipable ?? false} stepKey={sidePockets_Step.key} exitingSelectedKey={this.state.selected_SidePockets ? this.state.selected_SidePockets.key : null} opts={sidePockets_Step.opts} isFirst={false} isPhotoBox={sidePockets_Step.isPhotoBox} photoRatio={sidePockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={sidePockets_Step.boldText ?? false} boldText={sidePockets_Step.boldText ?? false} ></Step>
                    <Step name={backPockets_Step.name} skipable={backPockets_Step.skipable ?? false} stepKey={backPockets_Step.key} exitingSelectedKey={this.state.selected_BackPockets ? this.state.selected_BackPockets.key : null} opts={backPockets_Step.opts} isFirst={false} isPhotoBox={backPockets_Step.isPhotoBox} photoRatio={backPockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={backPockets_Step.boldText ?? false} boldText={backPockets_Step.boldText ?? false} ></Step>
                    <Step name={waistBandType_Step.name} skipable={waistBandType_Step.skipable ?? false} stepKey={waistBandType_Step.key} exitingSelectedKey={this.state.selected_WaistBandType ? this.state.selected_WaistBandType.key : null} opts={waistBandType_Step.opts} isFirst={false} isPhotoBox={waistBandType_Step.isPhotoBox} photoRatio={waistBandType_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={waistBandType_Step.boldText ?? false} boldText={waistBandType_Step.boldText ?? false} ></Step>
                    <Step name={pantClosure_Step.name} skipable={pantClosure_Step.skipable ?? false} stepKey={pantClosure_Step.key} exitingSelectedKey={this.state.selected_PantClosure ? this.state.selected_PantClosure.key : null} opts={pantClosure_Step.opts} isFirst={false} isPhotoBox={pantClosure_Step.isPhotoBox} photoRatio={pantClosure_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantClosure_Step.boldText ?? false} boldText={pantClosure_Step.boldText ?? false} ></Step>

                    <Step name={pantBottom_Step.name} skipable={pantBottom_Step.skipable ?? false} stepKey={pantBottom_Step.key} exitingSelectedKey={this.state.selected_PantBottom ? this.state.selected_PantBottom.key : null} opts={pantBottom_Step.opts} isFirst={false} isPhotoBox={pantBottom_Step.isPhotoBox} photoRatio={pantBottom_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantBottom_Step.boldText ?? false} boldText={pantBottom_Step.boldText ?? false} ></Step>
                    <Step name={monogram_Step.name} needsCustomInput={true} skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key} exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key : null} opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={monogram_Step.boldText ?? false} boldText={monogram_Step.boldText ?? false} ></Step>
                    {
                        is_pantMonogramThreadColor_Needed ?
                        <Step name={pantMonogramThreadColor_Step.name} needsCustomInput={false} skipable={pantMonogramThreadColor_Step.skipable ?? false} stepKey={pantMonogramThreadColor_Step.key} exitingSelectedKey={this.state.selected_PantMonogramThreadColor ? this.state.selected_PantMonogramThreadColor.key : null} opts={pantMonogramThreadColor_Step.opts} isFirst={false} isPhotoBox={pantMonogramThreadColor_Step.isPhotoBox} photoRatio={pantMonogramThreadColor_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={pantMonogramThreadColor_Step.boldText ?? false} boldText={pantMonogramThreadColor_Step.boldText ?? false} ></Step>

                        : null
                    }
                    
                    <Step name={toneStep.name} skipable={toneStep.skipable ?? false} stepKey={toneStep.key} exitingSelectedKey={this.state.selected_Tone ? this.state.selected_Tone.key : null} opts={toneStep.opts} isFirst={false} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={toneStep.boldText ?? false} boldText={toneStep.boldText ?? false} ></Step>
                    <Step name={fabricStep.name} skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key} exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key : null} opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={false} paddingLess={fabricStep.boldText ?? false} boldText={fabricStep.boldText ?? false} ></Step>
                    <Step name={pantButtons_Step.name} skipable={pantButtons_Step.skipable ?? false} stepKey={pantButtons_Step.key} exitingSelectedKey={this.state.selected_PantButtons ? this.state.selected_PantButtons.key : null} opts={pantButtons_Step.opts} isFirst={false} isPhotoBox={pantButtons_Step.isPhotoBox} photoRatio={pantButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} isLast={true} paddingLess={pantButtons_Step.boldText ?? false} boldText={pantButtons_Step.boldText ?? false} ></Step>

                </StepWizard>
            }
            {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
        </div>
        )
    }



    render() {
        return (
            this.jacketWizard()
        )
    }

}


export default WizardForPant
