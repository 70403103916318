import cogoToast from 'cogo-toast'
import React, { useEffect, useRef } from 'react'
import { currencySymbol, currencyCode } from '../clientInfo/clientInfo'

const PayPal = (props) => {

    const paypal = useRef()

    useEffect(() => {

        let description = `Bespoke Purchase via Concierge. OrderNo= ${props.order.orderNo} (devId=${props.order.objectId}). CustomerName= ${props.order.customerName} . CustomerPhone= ${props.order.customerPhone} . CustomerEmail= ${props.order.customerEmail}`

        // console.log('DESCRIPTION IS')
        // console.log(description)

        window.paypal.Buttons({
            createOrder: (data, actions, err) => {
                return actions.order.create({
                    intent: "CAPTURE",
                    purchase_units: [
                        {
                            description: `Bespoke Purchase via Concierge. OrderNo ${props.order.orderNo}`,
                            // description: description ,
                            amount: {
                                value: props.finalPrice,
                                currency_code : currencyCode
                            }
                        }
                    ]
                })
            },
            onApprove: async (data, actions) => {
                const order = await actions.order.capture()
                console.log('SUCCESSFUL ORDER')
                console.log(order)

                console.log('DATA')
                console.log(data)
                cogoToast.success("Done")

                props.paymentDone(order.id, "succeded", props.finalPrice, currencyCode, "PAYPAL")

            },
            onError: (err) => {
                cogoToast.error(err)
                props.errorOccured(err)
                console.log(err)
            }
        }).render(paypal.current)

    }, [ props, props.finalPrice])

    return (
        <div>
            <div ref={paypal}></div>
        </div>
    )

}


export default PayPal

